<template>
    <v-main class="grey lighten-4">
        <page-toolbar ref="toolbar" title="File Storage">
            <template #contents>
                <!--<v-btn text color="indigo" to="/console/platform/mturk/hit/create/">
                    <v-icon left>mdi-plus</v-icon>
                    Create HITs
                </v-btn>-->
            </template>
        </page-toolbar>

        <v-card id="card" class="d-flex">
            <div style="width: 50%;">
                <v-treeview
                    id="tutti-file-storage-treeview"
                    open-on-click
                    activatable
                    return-object
                    :active.sync="activeItem"
                    :open.sync="openDirs"
                    :items="files"
                    item-key="path"
                    :load-children="loadChildren"
                    style="padding: 30px;"
                >
                    <template v-slot:prepend="{ item, open }">
                        <v-icon v-if="item.type==='dir'">
                            {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                        </v-icon>
                        <v-icon v-else>
                            {{ getIconStringForFile(item.name) }}
                        </v-icon>
                    </template>
                    <template v-slot:label="{ item }">
                        <div>
                            <span class="mr-4">{{ item.name }}{{ item.type === 'dir' ? '/' : '' }}</span>
                            <v-menu offset-y>
                                <template v-slot:activator="{ attrs, on }">
                                    <v-btn
                                        v-if="item.type === 'dir'"
                                        icon
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon small>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item @click="openDialog('createFolder', item)">
                                        <v-list-item-title><v-icon small left>mdi-folder-plus</v-icon>New Folder</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="item.name!=='static'" @click="openDialog('renameFile', item, item.name)">
                                        <v-list-item-title><v-icon small left>mdi-pencil</v-icon>Rename</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="item.name!=='static'" @click="openDeleteFileDialog(item)">
                                        <v-list-item-title><v-icon small left>mdi-delete</v-icon>Delete</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="openUploadDialog('file', item)">
                                        <v-list-item-title><v-icon small left>mdi-upload</v-icon>Upload File(s)</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="openUploadDialog('folder', item)">
                                        <v-list-item-title><v-icon small left>mdi-upload</v-icon>Upload Folder</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </template>
                </v-treeview>
            </div>
            <v-divider vertical></v-divider>
            <div id="file-preview">
                <template v-if="activeItem.length > 0">
                    <img
                        id="preview-img"
                        v-if="['jpg','jpeg','png','gif','bmp','ico'].includes(getFileExtension(activeItem[0].path))"
                        :src="`/static/${activeItem[0].path}`"
                    />
                    <audio
                        controls
                        id="preview-audio"
                        v-if="['wav','mp3','m4a'].includes(getFileExtension(activeItem[0].path))"
                        :src="`/static/${activeItem[0].path}`"
                    />
                    <video
                        controls
                        id="preview-video"
                        v-if="['mp4','webm'].includes(getFileExtension(activeItem[0].path))"
                        :src="`/static/${activeItem[0].path}`"
                    />
                    <v-divider></v-divider>
                    <v-simple-table dense>
                        <tbody>
                            <tr><th>File path</th><td>/static/{{ activeItem[0].path }}</td></tr>
                            <tr>
                                <th>URL</th>
                                <td>
                                    <a
                                        :href="getUrlForPath(activeItem[0].path)"
                                        target="_blank"
                                    >
                                        {{ getUrlForPath(activeItem[0].path) }}
                                    </a>
                                    <v-icon small right>mdi-open-in-new</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <div class="text-center">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ attrs, on }">
                                <v-icon v-bind="attrs" v-on="on" class="ma-4" @click="download(activeItem[0])">mdi-download</v-icon>
                            </template>
                            <span>Download</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ attrs, on }">
                                <v-icon v-bind="attrs" v-on="on" class="ma-4" @click="openDeleteFileDialog(activeItem[0])">mdi-delete</v-icon>
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ attrs, on }">
                                <v-icon v-bind="attrs" v-on="on" class="ma-4" @click="openDialog('renameFile', activeItem[0], activeItem[0].name)">mdi-pencil</v-icon>
                            </template>
                            <span>Rename</span>
                        </v-tooltip>
                    </div>
                </template>
            </div>
        </v-card>

        <input
            multiple
            ref="uploadFilesForm"
            type="file"
            style="display:none"
            @click.stop="() => {}"
            @change="(e) => { onSelectUploadedFiles('file', e) }"
        />
        <input
            webkitdirectory
            ref="uploadFolderForm"
            type="file"
            style="display:none"
            @click.stop="() => {}"
            @change="(e) => { onSelectUploadedFiles('dir', e) }"
        />

        <tutti-dialog-alert
            ref="dialog"
            @confirm="onDialogConfirm(activeDialogItem.path, nameInput)"
        >
            <template #title>
                {{ dialogTitle }}
            </template>
            <template #body>
                <v-card-text>
                    <v-text-field v-model="nameInput" :label="dialogInputLabel" :rules="dialogInputRules"></v-text-field>
                </v-card-text>
            </template>
        </tutti-dialog-alert>

        <tutti-dialog-alert
            ref="dialogDeleteFile"
            @confirm="deleteFile(activeDialogItem.path)"
        >
            <template #title>
                <v-icon color="error" left>mdi-alert</v-icon>Delete {{ activeDialogItem.name }}
            </template>
            <template #body>
                <v-card-text>
                    Are you sure to delete <b>{{ activeDialogItem.name }}</b>?<br>
                    This operation cannot be undone.
                </v-card-text>
            </template>
        </tutti-dialog-alert>

        <tutti-dialog-alert
            ref="dialogUpload"
            @confirm="uploadFiles"
            @cancel="onCancelUpload"
        >
            <template #title>
                <v-icon color="warning" left>mdi-alert</v-icon>Some files already exist
            </template>
            <template #body>
                <v-card-text>
                    The following files already exist in the path.
                    Do you wish to overwrite them?<br>
                    This operation cannot be undone.
                    <textarea
                        readonly
                        id="textarea-existing-file-paths"
                        v-html="existingFilePaths.join('\n')"
                    ></textarea>
                </v-card-text>
            </template>
        </tutti-dialog-alert>

        <tutti-snackbar ref="snackbar"></tutti-snackbar>
    </v-main>
</template>
<script>
import PageToolbar from '@/components/ui/PageToolbar'
import TuttiDialogAlert from '@/components/ui/TuttiDialogAlert'
import rules from '@/lib/input-rules'
import MessagePack from "what-the-pack";
import TuttiSnackbar from '../../../ui/TuttiSnackbar.vue';
const { encode } = MessagePack.initialize(2**24);

const icons = {
    html: 'mdi-language-html5',
    js: 'mdi-nodejs',
    json: 'mdi-code-json',
    md: 'mdi-language-markdown',
    pdf: 'mdi-file-pdf',
    png: 'mdi-file-image-outline',
    jpg: 'mdi-file-image-outline',
    jpeg: 'mdi-file-image-outline',
    gif: 'mdi-file-image-outline',
    ico: 'mdi-file-image-outline',
    bmp: 'mdi-file-image-outline',
    txt: 'mdi-file-document-outline',
    xls: 'mdi-file-excel',
    mp3: 'mdi-file-music-outline',
    wav: 'mdi-file-music-outline',
    m4a: 'mdi-file-music-outline',
    mp4: 'mdi-file-video-outline',
    webm: 'mdi-file-video-outline',
};

let ductBufferKey = null;

const dialogInfo = {
    createFolder: {
        title: 'Create New Folder',
        inputLabel: 'Enter Folder Name',
        inputRules: [rules.required]
    },
    renameFile: {
        title: 'Rename',
        inputLabel: 'Enter New Name',
        inputRules: [rules.required]
    },
};

export default {
    components: { PageToolbar, TuttiDialogAlert, TuttiSnackbar },
    data: () => ({
        icons,
        activeItem: [],
        files: [],
        filesMap: {},
        openDirs: [],
        toolbarHeight: '48px',
        activeDialogItem: {},
        nameInput: '',
        existingFilePaths: [],
        filesToUpload: null,
        uploadProgress: 0,
        
        onDialogConfirm: () => {},

        dialogTitle: '',
        dialogInputLabel: '',
        dialogInputRules: [],
    }),
    props: ['client'],
    methods: {
        async loadChildren(file, refresh = false) {
            const path = (!file || file.path === '') ? '_static' : file.path;
            if(!refresh && file && this.filesMap[path].children.length > 0) return null;
            else {
                let files = await this.client._duct.call(
                    this.client._duct.EVENT.SYSTEM_STORAGE_LIST_FILES_FOR_PATH,
                    { path: file ? file.path : '' }
                );
                files.forEach(f => {
                    if(f.type === 'dir') f.children = [];
                    this.filesMap[f.path] = f;
                });
                if(file) {
                    this.filesMap[path].children = files.map(f => this.filesMap[f.path]);
                }
                return files;
            }
        },
        getFileExtension(name = '') {
            if(!name || name.split('.').length===1) return null;
            return name.split('.').slice(-1)[0];
        },
        getIconStringForFile(name) {
            const ext = this.getFileExtension(name);
            if(ext === null) return 'mdi-file-outline';
            return this.icons[ext] || 'mdi-file-outline';
        },
        getUrlForPath(path) {
            return `${window.location.origin}/static/${path}`;
        },
        openDialog(action, item, defaultInput = '') {
            this.onDialogConfirm = this[action];
            this.dialogTitle = dialogInfo[action].title;
            this.dialogInputLabel = dialogInfo[action].inputLabel;
            this.dialogInputRules = dialogInfo[action].inputRules;
            this.activeDialogItem = item;
            this.nameInput = defaultInput;
            this.$refs.dialog.show();
        },
        openDeleteFileDialog(item) {
            this.activeDialogItem = item;
            this.$refs.dialogDeleteFile.show();
        },
        download(item) {
            const link = document.createElement('a');
            link.download = item.name;
            link.href = this.getUrlForPath(item.path);
            link.click()
        },
        async createFolder(path, name) {
            const { success, content } = await this.client._duct.call(
                this.client._duct.EVENT.SYSTEM_STORAGE_CREATE_FOLDER,
                { path, name }
            );
            if(success) {
                const newPath = path !== '' ? `${path}/${name}` : name;
                this.filesMap[newPath] = {
                    name,
                    path: newPath,
                    type: 'dir',
                    children: [],
                };
                if(path === '') path = '_static';
                this.filesMap[path].children.push(this.filesMap[newPath]);
                this.filesMap[path].children.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                this.$refs.snackbar.show('success', `Created folder "${name}"`);
            } else {
                if(content.error_code===this.client.ERROR.SYSTEM_STORAGE.EXST_FOLDER){
                    this.$refs.snackbar.show('error', `Failed to create folder "${name}"; Folder already exists`);
                } else {
                    alert(`Unknown error: ${JSON.stringify(content.stacktrace)}`)
                }
            }
        },
        async renameFile(path, name) {
            const { success, content } = await this.client._duct.call(
                this.client._duct.EVENT.SYSTEM_STORAGE_RENAME_FILE,
                { path, name }
            );
            if(success) {
                const parentPath = path.split('/').length > 1 ? path.split('/').slice(0, -1).join('/') : '_static';
                const newPath = [...path.split('/').slice(0, -1), name].join('/');
                this.filesMap[newPath] = {
                    name,
                    path: newPath,
                    type: this.filesMap[path].type,
                    children: this.filesMap[path].type === 'dir' ? [] : undefined,
                }
                delete this.filesMap[path];
                for(let i in this.filesMap[parentPath].children) {
                    if(this.filesMap[parentPath].children[i].path === path) {
                        this.filesMap[parentPath].children.splice(i, 1, this.filesMap[newPath]);
                        break;
                    }
                }
                this.activeItem = [];
                this.$refs.snackbar.show('success', `Renamed file to "${name}"`);
            } else {
                if(content.error_code===this.client.ERROR.SYSTEM_STORAGE.EXST_FILE){
                    alert('File of the given name already exists!');
                } else {
                    alert(`Unknown error: ${JSON.stringify(content.stacktrace)}`);
                }
            }
        },
        async deleteFile(path) {
            const { success, content } = await this.client._duct.call(
                this.client._duct.EVENT.SYSTEM_STORAGE_DELETE_FILE,
                { path }
            );
            if(success) {
                const parentPath = path.split('/').length > 1 ? path.split('/').slice(0, -1).join('/') : '_static';
                delete this.filesMap[path];
                for(let i in this.filesMap[parentPath].children) {
                    if(this.filesMap[parentPath].children[i].path === path) {
                        this.filesMap[parentPath].children.splice(i, 1);
                        break;
                    }
                }
                this.activeItem = [];
                this.$refs.snackbar.show('success', `Deleted a file`);
            } else {
                alert(`Unknown error: ${content}`)
            }
        },
        openUploadDialog(type, item) {
            this.activeDialogItem = item;
            if(type === 'file') { this.$refs.uploadFilesForm.click(); }
            else if(type === 'folder') { this.$refs.uploadFolderForm.click(); }
        },
        async onSelectUploadedFiles(type, e) {
            Promise.all(Array.from(e.target.files).map( (f) => new Promise(
                (resolve, reject) => {
                    if(!f) reject();
                    let fr = new FileReader();
                    fr.onload = () => {
                        resolve({
                            path: f.webkitRelativePath !== '' ? f.webkitRelativePath : f.name,
                            data: fr.result,
                        });
                    }
                    fr.onerror = reject;
                    fr.readAsArrayBuffer(f);
                }
            ) )).then(async (files) => {
                const file_paths = type === 'dir' ? [files[0].path.split('/')[0]] : files.map((f) => f.path);
                const { success, content: existingFilePaths } = await this.client._duct.call(
                        this.client._duct.EVENT.SYSTEM_STORAGE_EXIST_FILES,
                        {
                            file_paths,
                            current_path: this.activeDialogItem.path,
                        }
                    );
                if(success) {
                    this.filesToUpload = files;
                    if(existingFilePaths.length > 0) {
                        this.existingFilePaths = existingFilePaths;
                        this.$refs.dialogUpload.show();
                    } else {
                        this.uploadFiles();
                    }
                }
            }).catch((err) => {
                console.error(err);
            }).finally(() => {
                e.target.value = '';
            });
        },
        async appendBuffer(bufferKey, blob, chunkSize) {
            for(let head=0; head<blob.length; head+=chunkSize){
                const uploadProgress = Math.min(blob.length, head+chunkSize)/blob.length*100;
                this.$refs.snackbar.show(undefined, `Uploading files ... (${uploadProgress}%)`, -1);
                await this.client._duct.call(
                    this.client._duct.EVENT.BLOBS_BUFFER_APPEND,
                    [ bufferKey, blob.slice(head, Math.min(blob.length, head+chunkSize)) ]
                );
            }
        },
        async uploadFiles() {
            await this.client._duct.call(this.client._duct.EVENT.BLOBS_BUFFER_OPEN, null);
            await this.appendBuffer(
                ductBufferKey,
                encode({
                    current_path: this.activeDialogItem.path,
                    files: this.filesToUpload,
                }),
                1024*512
            );
            const { success, content } = await this.client._duct.call(
                this.client._duct.EVENT.SYSTEM_STORAGE_UPLOAD_FILES,
                { ductBufferKey }
            );
            if(success) {
                this.$refs.snackbar.show('success', `Uploaded files`, 3000);
                this.loadChildren(this.activeDialogItem, true);
            } else {
                alert(`File upload failed: ${JSON.stringify(content.stacktrace)}`)
                this.$refs.snackbar.hide();
            }
            this.client._duct.send(this.client._duct.EVENT.BLOBS_BUFFER_OPEN, null);
            this.filesToUpload = null;
        },
        onCancelUpload() {
            this.filesToUpload = null;
        }
    },
    mounted() {
        this.toolbarHeight = this.$refs.toolbar.$el.style.height;

        this.client.invokeOnOpen(async () => {
            this.client._duct.setEventHandler(
                this.client._duct.EVENT.BLOBS_BUFFER_OPEN,
                (rid,eid,data) => { ductBufferKey = data; }
            );

            this.filesMap['_static'] = {
                name: 'static',
                path: '',
                type: 'dir',
                children: await this.loadChildren()
            };
            this.files = [this.filesMap['_static']];
        });
    }
}
</script>
<style>
#tutti-file-storage-treeview {
    height: 100%;
    overflow-y: scroll;
}
#tutti-file-storage-treeview .v-treeview-node__root {
    min-height: 32px;
}
#card {
    margin: 40px;
    height: 600px;
}
#file-preview {
    width: 50%;
    scroll-y: hidden;
    padding: 30px;
}
#preview-img {
    max-width: 90%;
    max-height: 50%;
    margin: 20px auto;
    display: block;
}
#preview-audio {
    max-width: 90%;
    margin: 20px auto;
    display: block;
}
#preview-video {
    max-width: 90%;
    margin: 20px auto;
    display: block;
}
#textarea-existing-file-paths {
    width: 100%;
    margin: 20px 0;
    border: 1px solid #ccc;
    height: 100px;
}
</style>